import { Button, Grid, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
//router
import { useNavigate } from "react-router-dom";
//icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//plaid
import { usePlaidLink } from "react-plaid-link";
import { baseUrl } from "../../../util/APIBaseUrl";
import {
  AddCustomerBankInfo,
  ResetAddCustomerBankInfo,
} from "../../../store/actions/Payment/AddBankAccountInfo";
//redux
import { useSelector, useDispatch } from "react-redux";

function AddPaymentMethod(props) {
  //props
  const { changeScreen, navigatedFrom } = props;
  let navigateTo = useNavigate();
  const dispatch = useDispatch();

  //component
  const [linkToken, setLinkToken] = React.useState(null);

  //redux state
  const addBankAccountInfo = useSelector(
    (state) => state.AddBankAccountInfo.addBankAccountInfo
  );

  const userAdditionalInfo = useSelector(
    (state) => state.UserAdditionalInfo.userAdditionalInfo
  );

  //functions
  const skipPaymentMethod = () => {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let accesstoken = localStorage.getItem("accessToken");
    if (accesstoken && loggedInUser) {
      navigateTo("/main/dashboard");
    } else {
      navigateTo("/");
    }
  };

  const getLinkToken = async () => {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const response = await fetch(`${baseUrl}/getLinkToken`, {
        method: "get",
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) {
        return;
      }
      const data = await response.json();

      setLinkToken(data);
    }
  };

  const onSuccess = React.useCallback((public_token) => {
    const setToken = async () => {
      let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

      let body = {
        customerID: loggedInUser?.userId,
        publicToken: public_token,
      };

      let accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        dispatch(
          AddCustomerBankInfo({
            request: `${baseUrl}/customer/${userAdditionalInfo?.customerId}/account`,
            body: JSON.stringify(body),
          })
        );
      }
    };
    setToken();
  }, []);

  const config = {
    token: linkToken?.link_token,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(config);

  //methods
  React.useEffect(() => {
    if (addBankAccountInfo?.ok) {
      navigateTo("/main/dashboard");
    }
    dispatch(ResetAddCustomerBankInfo({}));
  }, [addBankAccountInfo]);

  React.useEffect(() => {
    let config = {};

    if (linkToken != undefined && linkToken != null) {
      config = {
        token: linkToken.link_token,
        onSuccess,
      };
    }
  }, [linkToken]);

  React.useEffect(() => {
    getLinkToken();
  }, []);

  return (
    <Grid
      container
      direction="row"
      // justifyContent="center"
      alignItems="center"
      //sx={{ overflowY: "scroll" }}
    >
      <Grid
        item
        container
        direction="row"
        lg={8}
        xs={12}
        sm={12}
        md={8}
        sx={{ backgroundColor: "#081946", height: "100%" }}
        //justifyContent="flex-end"
      >
        <Grid item container xs={9.5} sx={{ zIndex: 11 }}>
          <Box
            // fullWidth
            component="img"
            sx={{
              // marginLeft: "65px",
              mt: "-5vh",
              ml: "-3vh",
              height: "60vw",
              // maxHeight: "120vh",
              width: "auto",
              // width: "calc(100vw - 500px)",
              position: "relative",
              // maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
            }}
            alt="Currency image"
            src="assets/fonts/Images/Untitled_Artwork 32 1.png"
          />
          <Box display="flex" flexDirection="column" maxWidth="500px" ml={22}>
            <Typography
              sx={{
                fontFamily: "Poppins-Bold !important",
                color: "#FFFFFF",
                fontSize: "48px",
                maxWidth: "380px",
                lineHeight: "50px",
                position: "relative",
                top: "-400px",
              }}
            >
              Set Up Monthly Payroll!
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular !important",
                color: "#FFFFFF",
                fontSize: "15px",
                maxWidth: "380px",
                lineHeight: "16px",
                position: "relative",
                top: "-380px",
              }}
            >
              Payment information is needed to setup monthly payroll for your
              employees. Payments are collected securely using Stripe ACH bank
              transfers.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            backgroundColor: "#DFF4F2",
            zIndex: 10,
            height: "100%",
          }}
        ></Grid>
        {/* <Grid
                    item
                    container
                    sx={{
                        position: "absolute",
                        bottom: 180,
                        left: "15%",
                        transform: "translateX(3%)",
                    }}
                >
                    <Grid item container direction="column" rowSpacing={2}>
                       
                    </Grid>
                </Grid> */}
      </Grid>

      <Grid
        item
        container
        direction="column"
        // alignItems="center"
        rowSpacing={1}
        lg={4}
        xs={12}
        sm={12}
        md={4}
        sx={{
          backgroundColor: "#DFF4F2",
          height: "100%",
          // paddingLeft: 25,
          // paddingRight: 20,
        }}
      >
        <Toolbar variant="dense" />

        <Grid item>
          <Box display="flex" flexDirection="row">
            <ArrowBackIcon
              textAlign="left"
              fontSize="12px"
              onClick={() => {
                navigatedFrom == "signUpScreen"
                  ? changeScreen(SignInScreenPermissions.SIGN_UP)
                  : navigatedFrom == "signInScreen"
                  ? changeScreen(SignInScreenPermissions.SignIn)
                  : null;
              }}
            />
            <Typography
              fontSize="13px"
              sx={{
                fontFamily: "Poppins-Medium !important",
                paddingLeft: "5px",
              }}
            >
              Back
            </Typography>
          </Box>
        </Grid>
        <Toolbar variant="dense" />
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Poppins-Bold !important",
              color: "secondary.main",
              fontSize: "26px",
              maxWidth: "430px",
              pb: "10px",
              lineHeight: "26px",
            }}
          >
            You can start by adding your payment method
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins-Bold !important",
              color: "#000000",
              fontSize: "14px",
              maxWidth: "500px",
              lineHeight: "10px",
            }}
          >
            Or you can always get it later!
          </Typography>
        </Grid>
        <Toolbar variant="dense" />
        <Toolbar variant="dense" />
        <Toolbar variant="dense" />

        <Grid item>
          <Button
            variant="contained"
            fullWidth={true}
            sx={{
              backgroundColor: "secondary.main",
              height: "50px",
              // width: "276px",
              width: "350px",
              color: "white",

              fontFamily: "Poppins-Regular !important",
            }}
            onClick={
              () => open()
              // navigateTo("/main/payment", {
              //     state: {
              //         AddPaymentMethodByDefault: true,
              //     },
              // })
            }
          >
            Add Payment Method
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth={true}
            sx={{
              height: "50px",
              width: "350px",
              fontFamily: "Poppins-Regular !important",
              color: "secondary.main",
            }}
            onClick={skipPaymentMethod}
          >
            Skip
            <ArrowForwardIcon sx={{ ml: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddPaymentMethod;
