import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Grid, Typography, Popover } from "@mui/material";
import { Box } from "@mui/system";
import { usePlaidLink } from "react-plaid-link";
import { useFlags } from "launchdarkly-react-client-sdk";

import AddEchInfo from "components/Screens/PaymentV2/AddEchInfo";
import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";
import { isEmptyObject } from "components/Screens/CommonUtils";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";

import { AddCustomerBankInfo, ResetAddCustomerBankInfo } from "store/actions/Payment/AddBankAccountInfo";
import { showSnackbar } from "store/actions/Utility";
import { baseUrl } from "util/APIBaseUrl";
import { getUserAdditionalInfo } from "selectors/user";

import EdgeIcon from "assets/svgs/edgeIcon";
import paymentProcessImage from "assets/images/payment-method-illustration.png";
import CopyIcon from "assets/images/copy.svg";
import InfoIcon from "assets/images/info-question-mark.svg";

function AddPaymentMethodV2() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { skipForNow: skipForNowFlag } = useFlags();

  const { loggedInUser, accessToken } = usePermissions();
  const { platfromRebrandPhase1 } = useFlags();

  const [linkToken, setLinkToken] = useState(null);
  const [openEch, setOpenEch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [plaidClosed, setPlaidClosed] = useState(false);

  const bankAccountsDetails = {};

  const addBankAccountInfo = useSelector((state) => state.AddBankAccountInfo.addBankAccountInfo);
  const loadingAddBankAccountInfo = useSelector((state) => state.AddBankAccountInfo.loadingAddBankAccountInfo);
  const userAdditionalInfo = useSelector(getUserAdditionalInfo);

  const { skipPayment } = userAdditionalInfo;
  const showSkipOption = skipForNowFlag ? skipPayment : true;

  const skipPaymentMethod = () => {
    if (accessToken && isEmptyObject(loggedInUser)) {
      navigateTo("/main/dashboard");
    } else {
      navigateTo("/");
    }
  };

  const handleEchClose = () => {
    setOpenEch(false);
  };

  const getLinkToken = async () => {
    if (accessToken) {
      const response = await fetch(`${baseUrl}/getLinkToken`, {
        method: "get",

        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) {
        return;
      }
      const data = await response.json();

      setLinkToken(data);
    }
  };

  const onSuccess = useCallback((publicToken) => {
    const setToken = async () => {
      const body = {
        customerID: loggedInUser?.userId,
        publicToken,
      };

      if (accessToken) {
        dispatch(
          AddCustomerBankInfo({
            request: `${baseUrl}/customer/${loggedInUser?.customerId}/account`,
            body: JSON.stringify(body),
          })
        );
      }
    };
    setToken();
  }, []);

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText("finance@edgeonline.co");
    handleCloseAnchor();
    dispatch(showSnackbar({ dispatch, type: "success", message: "Email address copied" }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const config = {
    token: linkToken?.link_token,
    onSuccess,
    onExit: () => setPlaidClosed(true),
  };
  const { open } = usePlaidLink(config);

  const openInfo = Boolean(anchorEl);
  const id = openInfo ? "simple-popover" : undefined;

  const displayInfoIcon = () => {
    return (
      <>
        <button
          type="button"
          className="add-payment-info-img cursor-pointer"
          aria-describedby={id}
          onClick={handleClick}
          onKeyPress={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              handleClick(event);
            }
          }}
        >
          <img
            src={InfoIcon}
            alt="info-icon"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </button>

        <Popover
          id={id}
          open={openInfo}
          anchorEl={anchorEl}
          onClose={handleCloseAnchor}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="popover-container">
            <div className="payment-popover-heading"> Need help?</div>
            <div className="payment-popover-desc mt-1">Email us at</div>

            <div
              className="payment-contact-email-wrapper cursor-pointer"
              role="button"
              tabIndex={0}
              onClick={copyToClipboard}
              onKeyPress={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  copyToClipboard();
                }
              }}
            >
              <div className="payment-contact-email">finance@edgeonline.co</div>
              <img src={CopyIcon} alt="copy-icon" />
            </div>
          </div>
        </Popover>
      </>
    );
  };

  // methods
  useEffect(() => {
    if (addBankAccountInfo?.ok) {
      navigateTo("/main/dashboard");
    }
    dispatch(ResetAddCustomerBankInfo({}));
  }, [addBankAccountInfo]);

  useEffect(() => {
    if (linkToken != undefined && linkToken != null) {
      config.token = linkToken.link_token;
      config.onSuccess = onSuccess;
    }
  }, [linkToken]);

  useEffect(() => {
    if (!isEmptyObject(bankAccountsDetails)) {
      getLinkToken();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Add Payment Method</title>
      </Helmet>
      {loadingAddBankAccountInfo && <PageLoader />}
      <AddEchInfo setOpenEch={setOpenEch} open={openEch} newCustomer Anchor={handleEchClose} />
      <Grid container direction="row" justifyContent="center" alignItems="start" sx={{ overflowY: "auto" }}>
        <Grid
          item
          container
          direction="row"
          xl={3}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{ backgroundColor: "#FBFAFF", minHeight: "100vh" }}
          justifyContent="start"
          paddingTop="100px"
          paddingLeft="45px"
          paddingRight="20px"
        >
          <Grid item>
            <Grid item>{platfromRebrandPhase1 ? <EdgeLogo size="lg" state="dark" /> : <EdgeIcon />}</Grid>
            <Grid item sx={{ paddingTop: "20px !important" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins !important",
                  color: "#000000",
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36px",
                }}
              >
                We just need a little more information to get you started
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  paddingTop: "16px",
                }}
              >
                Enter the following details to begin!
              </Typography>
            </Grid>
            <Grid item sx={{ paddingTop: "70px !important" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  paddingLeft: "44px",
                }}
              >
                STEP 1
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <span
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "99px",
                    backgroundColor: "#3D43BB",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  Company Details
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <span
                style={{
                  display: "block",
                  width: "4px",
                  height: "92px",
                  backgroundColor: "#B2B4E2",
                  margin: "10px 0px 10px 60px",
                }}
              />
            </Grid>
            <Grid item>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  paddingLeft: "44px",
                }}
              >
                STEP 2
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <span
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "99px",
                    backgroundColor: "#3D43BB",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  Payment Details
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xl={9}
          lg={9}
          md={9}
          sm={12}
          xs={12}
          direction="column"
          alignItems="start"
          rowSpacing={1}
          sx={{
            backgroundColor: "#FFFFFF",
            height: "100%",
            overflowY: "auto",
          }}
          padding="100px 86px 0px"
        >
          <form>
            <Grid>
              <img
                style={{
                  height: "250px",
                  maxWidth: "328px",
                  width: "250px",
                  objectFit: "contain",
                  marginLeft: "20px",
                  // override on screens greater than 912px
                  "@media screen and (min-width: 912px)": {
                    objectFit: "cover",
                    maxWidth: "420px",
                    width: "100%",
                  },
                }}
                alt="Payment Process"
                src={paymentProcessImage}
              />
            </Grid>
            <Grid item sx={{ maxWidth: "420px" }}>
              <Typography
                sx={{
                  color: "var(--Neutral-10, #14151F)",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "32px",
                  marginTop: "24px",
                }}
              >
                Add Payment Method
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  paddingTop: "4px",
                  marginTop: "8px",
                }}
              >
                To proceed with transactions smoothly, you can securely add your payment details here.{" "}
              </Typography>
              <br />
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  paddingTop: "4px",
                }}
              >
                Your information will be encrypted and securely stored for future transactions, providing you with peace
                of mind.{" "}
              </Typography>
            </Grid>

            <Grid item marginTop="24px" sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#3D43BB",
                  maxWidth: "280px",
                  height: "54px",
                  fontFamily: "Poppins-Regular !important",
                  fontWeight: "500",
                  lineHeight: "20px",
                  borderRadius: "99px",
                  fontSize: "16px",
                  color: "white",
                  boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                }}
                onClick={() => setOpenEch(true)}
              >
                Add ACH Details
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "transparent",
                  height: "54px",
                  fontFamily: "Poppins !important",
                  fontWeight: "600",
                  maxWidth: "280px",
                  position: "initial",
                  borderRadius: "99px",
                  fontSize: "16px",
                  color: "#3D43BB",
                  border: " 2px solid #3D43BB",
                  boxShadow: "none",

                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                  },
                }}
                onClick={() => open()}
              >
                Connect Bank via Plaid
              </Button>
              {showSkipOption && (
                <div style={{ width: "280px" }}>
                  <div
                    style={{
                      color: "#3D43BB",
                      margin: "auto",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      cursor: "pointer",
                      marginTop: "6px",
                    }}
                    onClick={skipPaymentMethod}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        skipPaymentMethod();
                      }
                    }}
                  >
                    Skip for now
                  </div>
                </div>
              )}
            </Grid>
          </form>

          {plaidClosed && (
            <div className="plaid-link-closed-container mt-2">
              <span className="plaid-link-close-desc">Can’t find your bank on Plaid? </span>
              <span
                className="plaid-link-close-btn cursor-pointer"
                onClick={() => setOpenEch(true)}
                onKeyPress={(event) => {
                  if (event.key === "Enter" || event.key === " ") {
                    setOpenEch(true);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {" "}
                Add ACH details manually.
              </span>
            </div>
          )}
          {displayInfoIcon()}
        </Grid>
      </Grid>
    </>
  );
}

export default AddPaymentMethodV2;
