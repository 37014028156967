import { call, put, takeEvery } from "redux-saga/effects";
import { DropdownDataSuccess, DropdownDataFailure } from "../../actions/DropdownData";
import { DROPDOWN_DATA } from "../../constants/DropdownData";
import postRequest from "../../../util/APIHelperPost";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchDropdownDataRequest = async () => {
  const body = JSON.stringify({
    filters: [
      "country",
      "customer",
      "department",
      "timezone",
      "edgePermission",
      "customerPermission",
      "bonusPreset",
      "employeeStatus",
      "customerStatus",
      "edgeDepartments",
      "salaryStatus",
      "documentTypes",
    ],
  });
  return await postRequest(`${baseUrl}/filterlists`, body)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

function* fetchDropdownData(params) {
  try {
    const response = yield call(fetchDropdownDataRequest, params);
    yield put(DropdownDataSuccess(response));
  } catch (error) {
    yield put(DropdownDataFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(DROPDOWN_DATA, fetchDropdownData);
}
