import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as LogoSm } from "./svgs/logo-sm.svg";
import { ReactComponent as IconSm } from "./svgs/icon-sm.svg";
import { ReactComponent as LogoMd } from "./svgs/logo-md.svg";
import { ReactComponent as IconMd } from "./svgs/icon-md.svg";
import { ReactComponent as LogoLg } from "./svgs/logo-lg.svg";
import { ReactComponent as IconLg } from "./svgs/icon-lg.svg";
import { ReactComponent as LogoXl } from "./svgs/logo-xl.svg";
import { ReactComponent as IconXl } from "./svgs/icon-xl.svg";

function EdgeLogo({ size = "md", icon = false, state = "dark" }) {
  const colorMap = {
    white: "#FFFFFF",
    black: "#000000",
    dark: "#4A0F70",
    color: "#914DE8",
  };

  const svgStyle = {
    color: colorMap[state],
  };

  const getLogo = () => {
    switch (size) {
      case "sm":
        return icon ? IconSm : LogoSm;
      case "md":
        return icon ? IconMd : LogoMd;
      case "lg":
        return icon ? IconLg : LogoLg;
      case "xl":
        return icon ? IconXl : LogoXl;
      default:
        return LogoMd;
    }
  };

  const Logo = getLogo();

  return (
    <div className={`edge-logo edge-logo-${size} edge-logo-${state}`}>
      <Logo style={svgStyle} />
    </div>
  );
}

EdgeLogo.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  icon: PropTypes.bool,
  state: PropTypes.oneOf(["color", "white", "dark", "black"]),
};

EdgeLogo.defaultProps = {
  size: "md",
  icon: false,
  state: "dark",
};

export default EdgeLogo;
