import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FolderOutlined as FolderOutlinedIcon } from "@mui/icons-material";

import Modal from "components/common/Modal";
import Button from "components/common/Button";
import Select from "components/common/Select";
import { AlertType } from "components/common/Snackbar";
import DocumentInfoSection from "components/Widgets/BambooHRTabs/Documents/DocumentInfoSection";

import { showSnackbar } from "store/actions/Utility";
import { getEmployeeDocumentCount, updateEmployeeDocumentInfo } from "services/BambooHrTabs/documents";
import DocumentMoveSchema from "validations/BambooHRTabs/DocumentMoveSchema";

import "./DocumentMoveModal.scss";
import "../Document.scss";

function DocumentMoveModal({ open, onClose, document, employeeId, onDocumentMove, onDocumentTypeChange }) {
  const dispatch = useDispatch();

  const { documentId, documentName, shareWithEmployee, shareWithCustomer, createdAt, documentType, documentExtension } =
    document || {};

  const documentTypes = useSelector((state) => state.DropdownData?.dropdownData?.payload?.documentTypes || []);

  const [isLoading, setIsLoading] = useState(false);
  const [folders, setFolders] = useState([]);

  const values = {
    folder: documentType || "",
  };

  const methods = useForm({
    resolver: yupResolver(DocumentMoveSchema),
    mode: "onChange",
    values,
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const getShareText = () => {
    if (shareWithEmployee && shareWithCustomer) {
      return "Shared with Employee and Customer";
    }
    if (shareWithEmployee) {
      return "Shared with Employee";
    }
    if (shareWithCustomer) {
      return "Shared with Customer";
    }
    return "";
  };

  const getEmployeeDocument = async () => {
    try {
      setIsLoading(true);
      const count = await getEmployeeDocumentCount(employeeId);
      setFolders(count);
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error?.message,
          dispatch,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const transformedTableData = folders
    .filter((fld) => {
      const docType = documentTypes.find((doc) => doc.id === fld.documentType);
      return fld.documentType !== documentType && docType?.actions?.move?.enabled;
    })
    .map((fld) => ({
      value: fld.documentType,
      label: fld.documentType,
    }));

  const onSubmit = async ({ folder }) => {
    try {
      setIsLoading(true);
      if (folder === documentType) return;
      await updateEmployeeDocumentInfo(employeeId, documentId, { documentType: folder });
      dispatch(
        showSnackbar({
          type: AlertType.success,
          snackbarMessageContent: (
            <div className="flex items-center space-x-025">
              <p className="para-body-m-semibold">{documentType}</p>
              <p className="para-body-m-regular">moved to</p>
              <p className="para-body-m-semibold">{folder}</p>
            </div>
          ),
          callToAction: {
            text: "Go to Folder",
            onClick: () => onDocumentTypeChange(folder),
          },
          dispatch,
        })
      );
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
      onDocumentMove();
    }
  };

  useEffect(() => {
    getEmployeeDocument();
  }, [employeeId]);

  return (
    <Modal open={open} onClose={onClose} title="Move to Folder" width="552px">
      <DocumentInfoSection
        className="my-1"
        text={getShareText()}
        documentName={documentName}
        documentExtension={documentExtension}
        date={createdAt}
      />
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit((data) => onSubmit(data, e))(e);
          }}
          className="move-to-folder-form"
        >
          <Select
            label="Move to"
            name="folder"
            placeholder="Select"
            startIcon={<FolderOutlinedIcon sx={{ color: "#AFAFAF" }} />}
            options={transformedTableData}
          />
          <div className="flex justify-end mt-1 mb-05">
            <Button label="Cancel" variant="outlined" size="large" color="primary" onClick={onClose} />
            <div className="ml-05">
              <Button
                isLoading={isLoading}
                isDisabled={!isValid}
                label="Save"
                size="large"
                type="submit"
                color="primary"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default DocumentMoveModal;
