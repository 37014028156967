import React from "react";

function PakistanFlag({ height, width }) {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0009 26.0017C20.1806 26.0017 26.0009 20.1814 26.0009 13.0017C26.0009 5.82201 20.1806 0.00170898 13.0009 0.00170898C5.82115 0.00170898 0.000854492 5.82201 0.000854492 13.0017C0.000854492 20.1814 5.82115 26.0017 13.0009 26.0017Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78284 1.58067C8.6298 0.572965 10.7481 0 13.0002 0C20.1799 0 26.0002 5.82034 26.0002 13C26.0002 20.1797 20.1799 26 13.0002 26C10.7481 26 8.6298 25.427 6.78284 24.4193V1.58067ZM13.429 14.3239C14.6173 15.9685 16.9139 16.3384 18.5586 15.1501C19.0714 14.7795 19.4603 14.3013 19.7165 13.7688C19.5226 14.8925 18.9071 15.9444 17.9098 16.6649C15.8856 18.1276 13.059 17.6722 11.5964 15.6481C10.1339 13.6239 10.5892 10.7973 12.6134 9.33471C13.6106 8.61418 14.8027 8.36007 15.9304 8.52872C15.3442 8.60484 14.768 8.82385 14.2552 9.19436C12.6106 10.3826 12.2406 12.6792 13.429 14.3239Z"
        fill="#496E2D"
      />
      <path
        d="M18.4881 8.47864L19.4145 9.47705L20.6503 8.90464L19.987 10.0941L20.9134 11.0927L19.577 10.8294L18.9137 12.019L18.7512 10.6667L17.4148 10.4033L18.6507 9.83094L18.4881 8.47864Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}

export default PakistanFlag;
