import React from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import CustomModal from "components/common/Modal";
import EditContactInfo from "components/Widgets/BambooHRTabs/Contact/EditContactInfo";
import EditEmergencyContact from "components/Widgets/BambooHRTabs/Contact/EditEmergencyContact";
import EditPersonalInfoModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EditPersonalInfoModal";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions/Utility";
import { AlertType } from "components/common/Snackbar";
import { updateEmergencyContact, updateEmployeeContactInfo } from "services/BambooHrTabs/contact";

function EmployeeAboutModal({ data, onUpdate, countryStates, openModal, setOpenModal, type }) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal(false);
    onUpdate();
  };

  const flags = useFlags();

  const handleSave = async (updatedData) => {
    try {
      switch (type) {
        case "Emergency Contact":
          await updateEmergencyContact(data.employeeId, updatedData);
          break;

        case "Contact":
          await updateEmployeeContactInfo(data.employeeId, {
            phoneNumber: updatedData.phoneNumber,
            personalEmail: updatedData.personalEmail,
            email: updatedData.email,
            addressLine1: updatedData.addressLine1,
            zip: updatedData.zip,
            country: updatedData.country,
            city: updatedData.city,
            state: updatedData.state,
          });
          break;

        default:
          break;
      }

      dispatch(showSnackbar({ type: AlertType.success, message: "Profile updated successfully", dispatch }));
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      handleClose();
    }
  };

  const emergencyContactData = data?.emergencyContact;
  if (type === "Personal") {
    return (
      <EditPersonalInfoModal
        open={openModal}
        closeModal={handleClose}
        initialPersonalDataWorkforce={{ ...data, nationalID: data?.cnic_dni_cex }}
        type="employee"
        flags={flags}
      />
    );
  }
  if (type === "Contact" || type === "Emergency Contact") {
    return (
      <CustomModal open={openModal} title={type} onClose={handleClose} width="652px">
        {type === "Contact" && (
          <EditContactInfo
            initialData={data}
            onSave={handleSave}
            onCancel={handleClose}
            countryStates={countryStates}
          />
        )}
        {type === "Emergency Contact" && (
          <EditEmergencyContact
            onSave={handleSave}
            onCancel={handleClose}
            initialData={emergencyContactData}
            countryStates={countryStates}
          />
        )}
      </CustomModal>
    );
  }
}

export default EmployeeAboutModal;
