import React from "react";
import moment from "moment";

import { PictureAsPdfOutlined, ImageOutlined } from "@mui/icons-material";

import "./document-info-section.scss";

function DocumentInfoSection({ date, className, text, documentExtension, documentName }) {
  const formattedDate = date ? moment(date).format("DD MMM, YYYY") : "N/A";

  return (
    <div className={`${className} document-info-container`}>
      <div className="flex items-center gap-075">
        {documentExtension === ".pdf" ? <PictureAsPdfOutlined color="primary" /> : <ImageOutlined color="primary" />}
        <p className="heading-h4-medium">{documentName}</p>
      </div>
      {text && <p className="para-body-m-medium color-text-primary">{text}</p>}
      {date && <p className="para-body-m-medium color-text-body-primary">Added on {formattedDate}</p>}
    </div>
  );
}

export default DocumentInfoSection;
