import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwt from "jwt-decode";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Alert,
  List,
  ListItem,
} from "@mui/material";
import { Box } from "@mui/system";

import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import ErrorsIndicatorList from "components/common/ErrorsIndicatorList/ErrorsIndicatorList";
import PageLoader from "components/common/PageLoader";
import TermsAndPolicy from "components/common/TermAndPolicy";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";
import { usePermissions } from "components/Hook";

import { AuthUser, ResetAuthUser } from "store/actions/AuthUser";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

import { baseUrl } from "util/APIBaseUrl";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const userPassword = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function ChangePassword(props) {
  const { customerOnBoardingPassword } = props;
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { loggedInUser } = usePermissions();
  const { platfromRebrandPhase1 } = useFlags();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [changedPassword, SetChangePassword] = useState(userPassword);
  const [loginButtonClicked, setLoginButtonClicked] = useState(false);
  const [errorsListConfirmPass, setErrorsListConfirmPass] = useState([{ message: "Passwords match" }]);

  const authUserData = useSelector((state) => state.AuthUser.authUserData);
  const loadingAuthUserData = useSelector((state) => state.AuthUser.loadingAuthUserData);
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const eventParam = queryParams.get("event");

  const decodeToken = useMemo(() => {
    if (token) {
      return jwt(token);
    }
    return {};
  }, [token]);

  useEffect(() => {
    const tokenExpired = Math.floor(new Date() / 1000) > decodeToken?.exp;

    if (tokenExpired) {
      navigateTo(`/resend-link?event=${eventParam}&token=${token}&tokenStatus=expired`);
    }

    if (
      authUserData?.ok === false &&
      authUserData?.payload?.error[0]?.description === "Link has already been used." &&
      authUserData?.payload?.error?.[0]?.statusCode === 400
    ) {
      navigateTo(`/resend-link?event=${eventParam}&token=${token}&tokenStatus=used`);
    }
  }, [decodeToken, authUserData]);

  const [errorsListNewPass, setErrorsListNewPass] = useState([
    {
      id: 1,
      message: "Must be at least 8 characters long",
    },
    {
      id: 2,
      message: "Must include a mix of uppercase and lowercase characters",
    },
    {
      id: 3,
      message: "Must include numbers",
    },
    {
      id: 4,
      message: "Must include special characters",
    },
  ]);

  // functions
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const handleClickShowSetPassword = () => setShowSetPassword(!showSetPassword);
  const handleMouseDownSetPassword = () => setShowSetPassword(!showSetPassword);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleErrors = (event, userFieldInput) => {
    const { name } = event.target;
    if (name === "newPassword") {
      const updatedErrorsList = errorsListNewPass.map((error) => {
        switch (error.id) {
          case 1:
            return {
              ...error,
              isChecked: userFieldInput.length >= 8,
            };
          case 2:
            return {
              ...error,
              isChecked: /[a-z]/.test(userFieldInput) && /[A-Z]/.test(userFieldInput),
            };
          case 3:
            return {
              ...error,
              isChecked: /\d/.test(userFieldInput),
            };
          case 4:
            return {
              ...error,
              isChecked: /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(userFieldInput),
            };
          default:
            return error;
        }
      });

      setErrorsListNewPass(updatedErrorsList);
    }

    if (name === "confirmPassword" || name === "newPassword") {
      setErrorsListConfirmPass([
        {
          ...errorsListConfirmPass[0],
          isChecked:
            name === "confirmPassword"
              ? userFieldInput === changedPassword.newPassword
              : changedPassword.confirmPassword === userFieldInput,
        },
      ]);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    handleErrors(event, value);

    SetChangePassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getError = (errors) => {
    if (errors != null && errors !== undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {`${error?.msg || error?.description || "Undefined Error"} ${error?.param || ""}`}
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view !== "textField") {
        if (changedPassword[field]?.key != null) {
          return false;
        }
        return true;
      }
      if (changedPassword[field] !== "") {
        return false;
      }
      return true;
    }
    return false;
  };

  const loginHandler = () => {
    let request = "";

    setLoginButtonClicked(true);
    if (changedPassword?.newPassword && changedPassword?.currentPassword) {
      if (changedPassword?.newPassword === changedPassword?.confirmPassword) {
        setOpenAlert(false);
        setPasswordMatch(true);
        request = `${baseUrl}/auth/password`;

        const payload = {
          password: changedPassword?.currentPassword,
          newPassword: changedPassword?.newPassword,
          email: changedPassword?.email,
          type: decodeToken?.userType,
          confirmPassword: changedPassword?.confirmPassword,
          token,
        };

        dispatch(
          AuthUser({
            userCredential: payload,
            loginPortal: decodeToken?.userType,
            request,
          })
        );
      } else if (changedPassword?.newPassword !== changedPassword?.confirmPassword) {
        dispatch(ResetAuthUser({}));
        setOpenAlert(true);
        setPasswordMatch(false);
      }
    } else {
      setOpenAlert(true);
    }
  };

  let errorMessage;

  if (!authUserData?.ok) {
    errorMessage = getError(authUserData?.payload?.error);
  } else if (!passwordMatch) {
    errorMessage = "Password does not match";
  } else {
    errorMessage = "REQUIRED FIELD MISSING!";
  }

  useEffect(() => {
    const accesstoken = localStorage.getItem("accessToken");
    let request = "";
    if (accesstoken && loggedInUser) {
      const userInformation = JSON.parse(localStorage.getItem("loggedInUser"));

      window.analytics?.track("Logged In", {
        channel: "reset",
        name: userInformation?.name,
        userID: userInformation?.userId,
        email: userInformation?.email,
        accountType: userInformation?.type,
      });

      if (loggedInUser?.type === "customer") {
        request = `customer/${loggedInUser.customerId}/info`;
      } else if (loggedInUser?.type === "edge") {
        request = `user/${loggedInUser.userId}/summary`;
      } else if (loggedInUser?.type === "employee") {
        request = `employee/${loggedInUser.userId}`;
      }
      dispatch(UserAdditionalInfo(request));
    } else if (window.location.pathname === "/admin") {
      navigateTo("/admin");
    } else if (window.location.pathname === "/employee") {
      navigateTo("/employee");
    }

    if (Object.keys(authUserData).length > 0 && authUserData.ok !== true) {
      setOpenAlert(true);
    }
  }, [authUserData]);

  useEffect(() => {
    const accesstoken = localStorage.getItem("accessToken");

    if (userAdditionalInfo && Object.keys(userAdditionalInfo).length && accesstoken && loggedInUser) {
      if (loggedInUser?.type === "customer") {
        if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          navigateTo("/addPaymentMethod");
        } else {
          navigateTo("/main/dashboard");
        }
      } else {
        navigateTo("/main/dashboard");
      }
    }
    if (userAdditionalInfo && Object.keys(userAdditionalInfo).length > 0 && !userAdditionalInfo.ok) {
      setOpenAlert(true);
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    if (customerOnBoardingPassword) {
      SetChangePassword({
        ...changedPassword,
        currentPassword: customerOnBoardingPassword,
      });
    }
  }, [customerOnBoardingPassword]);

  useEffect(() => {
    if (token) {
      const data = {
        email: decodeToken?.email,
        currentPassword: decodeToken?.password,
        type: decodeToken?.userType,
      };

      SetChangePassword({
        ...changedPassword,
        ...data,
      });
    }
  }, [token]);

  useEffect(() => {
    const { email, name, userType } = decodeToken;
    if (email && name && userType) {
      window?.heap?.identify(email);
      const userProperties = {
        "Full Name": name,
        "User Type": userType,
      };
      window?.heap?.addUserProperties(userProperties);
    }
  }, [window?.heap, decodeToken]);

  useEffect(() => {
    dispatch(ResetAuthUser({}));
  }, []);

  return (
    <>
      <Helmet>
        <title>Edge | Change Password</title>
      </Helmet>

      {loadingAuthUserData && <PageLoader />}

      <Grid container justifyContent="space-evenly">
        <Grid
          container
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding="20px 0px"
          backgroundColor="#FFFFFF"
          height="100vh"
          sx={{
            overflow: "scroll",
          }}
        >
          <form onSubmit={handleSubmit(loginHandler)}>
            <Grid item sx={{ maxWidth: "318px", width: "318px" }}>
              <Grid item>
                {platfromRebrandPhase1 ? (
                  <EdgeLogo size="xl" state="dark" />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      height: "47px",
                      width: "91px",
                      objectFit: "cover",
                    }}
                    alt="edge"
                    src="assets/Images/edge-logo.svg"
                  />
                )}
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Bold !important",
                    color: "#000000",
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "44px",
                    marginTop: "20px",
                    "@media screen and (min-width: 912px)": {
                      marginTop: "0px",
                    },
                  }}
                >
                  {`Welcome, ${decodeToken?.name}!`}
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: "16px" }}>
                <FormControl variant="outlined" fullWidth error>
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular !important",
                      color: checkError("email", "textField") ? "#292A3D" : "000000",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "400",
                      letterSpacing: "0.25px",
                    }}
                  >
                    Email Address
                  </Typography>
                  <Typography
                    fontWeight={500}
                    sx={{
                      fontFamily: "Poppins-Bold !important",
                      color: checkError("email", "textField") ? "#F64A14" : "080D46",
                      fontSize: "14px",
                      paddingBottom: 2,
                    }}
                  >
                    {decodeToken?.email}
                  </Typography>
                </FormControl>
              </Grid>
              {!token && (
                <Grid item sx={{ marginTop: "16px" }}>
                  <FormControl variant="outlined" fullWidth>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #B2B4E2",
                        borderRadius: "12px",
                        paddingLeft: "4px",
                      }}
                    >
                      <TextFieldV2
                        id="password"
                        placeholder="Enter Password"
                        name="currentPassword"
                        value={changedPassword?.currentPassword}
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        onChange={handleInputChange}
                        error={checkError("currentPassword", "textField")}
                        label="Old Password"
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOutlined sx={{ color: "#3D3E5C" }} />
                                ) : (
                                  <VisibilityOffOutlined sx={{ color: "#3D3E5C" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </FormControl>
                </Grid>
              )}
              <Grid item sx={{ marginTop: "16px" }}>
                <FormControl variant="outlined" fullWidth>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #B2B4E2",
                      borderRadius: "12px",
                      paddingLeft: "4px",
                    }}
                  >
                    <TextFieldV2
                      id="newPassword"
                      placeholder="New Password"
                      name="newPassword"
                      type={showNewPassword ? "text" : "password"}
                      fullWidth
                      onChange={handleInputChange}
                      error={checkError("newPassword ", "textField")}
                      label="New Password"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownNewPassword}
                            >
                              {showNewPassword ? (
                                <VisibilityOutlined sx={{ color: "#3D3E5C" }} />
                              ) : (
                                <VisibilityOffOutlined sx={{ color: "#3D3E5C" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <ErrorsIndicatorList errors={errorsListNewPass} />

              <Grid item sx={{ marginTop: "16px" }}>
                <FormControl variant="outlined" fullWidth>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #B2B4E2",
                      borderRadius: "12px",
                      paddingLeft: "4px",
                    }}
                  >
                    <TextFieldV2
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      type={showSetPassword ? "text" : "password"}
                      fullWidth
                      onChange={handleInputChange}
                      error={checkError("confirmPassword ", "textField")}
                      label="Confirm Password"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowSetPassword}
                              onMouseDown={handleMouseDownSetPassword}
                            >
                              {showSetPassword ? (
                                <VisibilityOutlined sx={{ color: "#3D3E5C" }} />
                              ) : (
                                <VisibilityOffOutlined sx={{ color: "#3D3E5C" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>

              <ErrorsIndicatorList errors={errorsListConfirmPass} />

              <Grid item sx={{ marginTop: "16px" }}>
                {authUserData?.ok === false || passwordMatch === false
                  ? openAlert && (
                      <Alert
                        open={openAlert}
                        autoHideDuration={350000}
                        variant="filled"
                        onClose={handleAlertClose}
                        icon={false}
                        sx={{
                          backgroundColor: "#F64A14",
                          padding: "initial",
                          px: "5px",
                          fontSize: "12px",
                          textAlign: "center",

                          borderRadius: "5px !important",
                          color: "#ffffff !important",
                          "&.MuiPaper-root": {
                            color: "#ffffff !important",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular !important",
                            fontWeight: "400",
                            fontSize: "13px",
                          }}
                        >
                          {errorMessage}
                        </Typography>
                      </Alert>
                    )
                  : null}
              </Grid>
              <Grid item sx={{ marginTop: "16px" }}>
                <Button
                  type="submit"
                  id="letsGo"
                  variant="contained"
                  disabled={
                    !errorsListNewPass.every((err) => err.isChecked) ||
                    !errorsListConfirmPass.every((err) => err.isChecked)
                  }
                  fullWidth
                  sx={{
                    backgroundColor: "#3D43BB",
                    height: "54px",
                    fontFamily: "Poppins-Regular !important",
                    fontWeight: "500",
                    lineHeight: "20px",
                    borderRadius: "99px",
                    fontSize: "16px",
                    color: "white",
                    boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                  }}
                >
                  Continue
                </Button>
              </Grid>
              <TermsAndPolicy userType={decodeToken?.userType} />
            </Grid>
          </form>
        </Grid>
        <Grid
          container
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          backgroundColor="#3D43BB"
          minHeight="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              maxWidth: "528px",
              width: "100%",
              objectFit: "contain",
              backgroundColor: "#3D43BB",
              // override on screens greater than 912px
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                maxWidth: "400px",
                width: "100%",
              },
            }}
            alt="The house from the offer."
            src="assets/Images/login-image.png"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ChangePassword;
