import { baseUrl } from "./APIBaseUrl";
import { makeRequest } from "./APIHelper";

const patchRequest = async ({
  path = "",
  body = {},
  loaderFlag = false,
  contentType = "application/json",
  apiType = null,
  stringifyBody = false,
}) => {
  const updatedBody = stringifyBody ? JSON.stringify(body) : body;
  const options = {
    path: `${baseUrl}/${path}`,
    method: "PATCH",
    body: updatedBody,
    contentType,
  };
  return await makeRequest(options, loaderFlag, apiType);
};
export default patchRequest;
