import { baseUrl } from "util/APIBaseUrl";
import { makeRequest } from "util/APIHelper";
import getRequest from "util/APIHelperGet";
import patchRequest from "util/APIHelperPatch";
import postRequest from "util/APIHelperPost";

// eslint-disable-next-line import/prefer-default-export
export const getMissingPaymentMethodCustomersCount = async () => {
  const { payload } = await getRequest(
    `${baseUrl}/customers?${new URLSearchParams({
      limit: 10,
      page: 0,
      orderBy: JSON.stringify({}),
      filter: JSON.stringify({
        paymentMethodType: ["MISSING"],
      }),
    })}`
  );

  return !!payload?.count;
};

export const getEmployeeSuccessManagersList = async (limit = 1000) => {
  const { payload } = await getRequest(
    `${baseUrl}/users/list?${new URLSearchParams({
      limit,
      filter: JSON.stringify({
        Role: ["EdgeAdmin", "EdgeEmployee"],
      }),
    })}`
  );

  return payload;
};

export const getEmployeeOfferLetterSignedURL = async (name, size, fileType) => {
  const { payload } = await getRequest(
    `${baseUrl}/employees/document/upload-url?${new URLSearchParams({
      fileName: name,
      fileType,
      fileSize: size,
    })}`
  );

  return payload;
};

export const updateEmployeePersonalDetails = async (body) => {
  return await makeRequest(
    {
      path: `${baseUrl}/v2/employee/${body.employeeId}`,
      contentType: "application/json",
      method: "PATCH",
      body: JSON.stringify(body),
    },
    false
  );
};

export const uploadWorkforcePhoto = async (actions) => {
  await fetch(`${baseUrl}${actions.request}`, {
    method: "POST",
    headers: {},
    body: actions.formData,
  });
};

export const addNewEmployee = async (body) => {
  return await postRequest(`${baseUrl}/v2/employees`, JSON.stringify(body));
};

export const assignEmployeeToACustomer = async (employeeId, body) => {
  return await postRequest(`${baseUrl}/v2/employee/${employeeId}/assign`, JSON.stringify(body));
};

export const editEmployeeAssignment = async (employeeId, body) => {
  return await postRequest(`${baseUrl}/employee/${employeeId}/editAssignment`, JSON.stringify(body));
};

export const unassignEmployeeFromACustomer = async (employeeId) => {
  return await getRequest(`${baseUrl}/employee/${employeeId}/unassign`);
};

export const getEmployeeDetailsByIDV2 = async (employeeId) => {
  return await getRequest(`${baseUrl}/v2/employee/${employeeId}`);
};

export const markEmployeeAsInactive = async (employeeId, body) => {
  return await postRequest(`${baseUrl}/employee/${employeeId}/deactivate`, JSON.stringify(body));
};

export const updateSkipPaymentOption = async (customerId, body) => {
  return await patchRequest({
    path: `customer/${customerId}`,
    body,
    stringifyBody: true,
  });
};
