import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";

import EdgeLogo from "assets/svgs/EdgeLogo";
import "components/Screens/Login/SignUp/Auth0Login/Auth0Login.scss";

function Auth0Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={() => {
        loginWithRedirect();
      }}
      className="auth0-button"
    >
      <div className="auth0-button-logo">
        <EdgeLogo />
      </div>
      <Typography>Continue with Edge</Typography>
    </Button>
  );
}

export default Auth0Login;
