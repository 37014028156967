import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TagManager from "react-gtm-module";
import { Auth0Provider } from "@auth0/auth0-react";

import CustomSnackbar from "components/common/Snackbar";
import SegmentScript from "segment";
import TrackPageViews from "TrackPageViews";

import Routes from "routes";

import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

import "styles/Typography.scss";
import "styles/Colors.scss";
import "styles/BoxShadows.scss";
import "styles/BorderRadius.scss";
import "./App.css";
import "./styles.css";
import "./customFieldsStyling.scss";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateTo = useNavigate();

  const {
    open,
    type,
    message = "",
    snackbarMessageContent = null,
    prefixIcon = null,
    callToAction = {},
  } = useSelector((state) => state?.Utility?.snackbar);

  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const { AUTH0_DOMAIN: domain, AUTH0_CLIENT_ID: clientId, AUTH0_AUDIENCE: audience } = platformConfig?.payload || {};

  React.useEffect(() => {
    const userFromStorage = JSON.parse(localStorage.getItem("loggedInUser"));
    const accessToken = localStorage.getItem("accessToken");
    // If user and accesstoken is not null- Send request to fetch additional user details
    if (accessToken && userFromStorage) {
      const reqUrl = {
        edge: `user/${userFromStorage.userId}/summary`,
        customer: `customer/${userFromStorage.customerId}/info`,
        employee: `employee/${userFromStorage.userId}`,
      };
      dispatch(UserAdditionalInfo(reqUrl[userFromStorage.type]));
    } else if (
      (!accessToken || !userFromStorage) &&
      (location.pathname === "/employee" || location.pathname === "/admin" || location.pathname === "/signup")
    ) {
      navigateTo(`${location.pathname}`);
    }
  }, []);

  React.useEffect(() => {
    if (platformConfig?.payload?.GTM) {
      const { id, auth, preview, isValid } = JSON.parse(platformConfig?.payload?.GTM);
      const tagManagerArgs = {
        gtmId: id,
        auth,
        preview,
      };

      if (isValid) {
        TagManager.initialize(tagManagerArgs);
      }
    }
  }, [platformConfig]);

  if (!domain || !clientId || !audience) {
    return (
      <AppContent
        open={open}
        message={message}
        type={type}
        snackbarMessageContent={snackbarMessageContent}
        prefixIcon={prefixIcon}
        callToAction={callToAction}
        environment={platformConfig?.payload?.ENVIRONMENT}
      />
    );
  }
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
    >
      <AppContent
        open={open}
        message={message}
        type={type}
        snackbarMessageContent={snackbarMessageContent}
        prefixIcon={prefixIcon}
        callToAction={callToAction}
        environment={platformConfig?.payload?.ENVIRONMENT}
      />
    </Auth0Provider>
  );
}

function AppContent({ environment, ...props }) {
  return (
    <>
      <CustomSnackbar {...props} />
      <SegmentScript environment={environment} />
      <TrackPageViews />
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#F7F7F7",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Routes />
      </Box>
    </>
  );
}

export default App;
