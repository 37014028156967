import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { IconButton } from "@mui/material";

import CustomButton from "components/common/Button";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import "../Document.scss";

function NoDocumentScreen({ onAddDocumentButtonClicked, loggedInUserType, showAddButton }) {
  const { bambooHrBulkUpload } = useFlags();

  if (loggedInUserType === "edge") {
    return (
      <div className="flex flex-col justify-center items-center w-full py-1">
        <IconButton className="no-docs-icon-button">
          <InsertDriveFileOutlinedIcon className="no-document-icon" />
        </IconButton>

        <div className="mt-05 mb-075 flex flex-col items-center">
          <div className="heading-h4-semibold">You have not added any documents to this folder yet</div>
          <div className="para-body-m-regular light-grey-text">Start by adding a new document.</div>
        </div>
        <CustomButton
          startIcon={<AddOutlinedIcon />}
          onClick={onAddDocumentButtonClicked}
          size="large"
          color="primary"
          isDisabled={!showAddButton && bambooHrBulkUpload}
          variant="contained"
          label="Add Document"
        />
      </div>
    );
  }

  return (
    <div className="no-documents-shared flex items-center justify-center px-15 py-1">
      <p className="para-body-l-regular no-documents-shared-text">No document shared yet</p>
    </div>
  );
}

export default NoDocumentScreen;
