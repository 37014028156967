import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";

import { getOrdinalSuffix } from "util/utils";
import { baseUrl } from "util/APIBaseUrl";
import {
  getCountryFlag,
  getHolidayDate,
  getHolidayDay,
  getHolidayEndDate,
  getMonthFromDate,
} from "components/Screens/CommonUtils";

import "./UpcomingHolidayCard.css";

function UpcomingHolidayCard({ holiday }) {
  return (
    <Box item xs={3} className="holidaycard">
      <CardMedia
        component="img"
        image={`${baseUrl}/publicHoliday/${holiday?.Holiday_Id}/logo?id=${holiday?.Picture_Version}`}
        alt={holiday?.Picture_Path}
        className="holidaycard-image"
      />
      <Grid className="holidaycard-content">
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography className="holidaycard-title" id="holiday-name">
            {holiday?.Name}
          </Typography>
          <Typography className="holidaycard-date" id="hoiday-date">
            {`${getHolidayDate(holiday?.StartDate)}${getOrdinalSuffix(
              getHolidayDate(holiday?.StartDate)
            )}  ${getMonthFromDate(holiday?.StartDate)}  (${getHolidayDay(holiday?.StartDate)})  ${getHolidayEndDate(
              holiday?.EndDate
            )} `}
          </Typography>
        </Grid>
        <div className="flex items-center gap-075 mt-1">
          <div className="flex items-center gap-0125">
            {holiday?.Region?.map((region) => (
              <div key={region} className="flex items-center">
                {getCountryFlag(region)}
              </div>
            ))}
          </div>
          <Button size="sm" variant="outlined" disableRipple className="holidaycard-button">
            {`${holiday?.employeesCount} Employees will be on this holiday`}
          </Button>
        </div>
      </Grid>
    </Box>
  );
}

export default UpcomingHolidayCard;
