import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";

import Button from "components/Button/Button";
import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import { AlertType } from "components/common/Snackbar";
import PageLoader from "components/common/PageLoader";
import CompanyFeedbackSuccessMessage from "components/Screens/Feedback/CompanyFeedbackSuccessMessage";

import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";
import { showSnackbar } from "store/actions/Utility";

import "components/Screens/Feedback/Feedback.css";

const MAX_CHARACTER_LIMIT = 5000;

function CompanyFeedbackForm({ onCancel }) {
  const [feedback, setFeedback] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [responseText, setResponsetext] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const apiUrl = `${baseUrl}/company-feedback`;

    const bodyData = {
      text: feedback,
    };

    try {
      const response = await postRequest(apiUrl, JSON.stringify(bodyData), true, "POST");
      if (response.status === 200) {
        setSubmissionSuccess(true);
        setResponsetext(response.payload.message);
        setFeedback("");
      } else {
        throw new Error(response.payload.error[0].description);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: "Something went wrong, please try again later.",
          dispatch,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsCharacterLimitExceeded(feedback.length > MAX_CHARACTER_LIMIT);
  }, [feedback]);

  if (submissionSuccess) {
    return <CompanyFeedbackSuccessMessage responseText={responseText} onBack={onCancel} />;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="body1" className="anonymousText">
          Your feedback is completely anonymous. We are not tracking any identifying information.
        </Typography>
        <TextFieldV2
          margin="normal"
          required
          fullWidth
          label="Your Feedback"
          name="feedback"
          multiline
          rows={10}
          variant="outlined"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          InputProps={{
            style: {
              paddingTop: "20px",
              paddingBottom: "20px",
              fontSize: "1rem",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              minHeight: "250px",
            },
            "& .MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
          }}
        />
        {!isCharacterLimitExceeded && (
          <Typography color="primary" variant="body2">
            {`Characters limit: ${feedback.length}/${MAX_CHARACTER_LIMIT}`}.
          </Typography>
        )}
        <Box className="buttonsBox">
          <Button type="button" variant="secondary" size="md" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" size="md" disabled={isCharacterLimitExceeded}>
            Submit
          </Button>
        </Box>
        {isCharacterLimitExceeded && (
          <Typography color="error" variant="body2">
            {`Characters limit exceeded (${feedback.length}/${MAX_CHARACTER_LIMIT})`}.
          </Typography>
        )}
      </Box>
    </>
  );
}

export default CompanyFeedbackForm;
