import { baseUrl } from "util/APIBaseUrl";
import deleteRequest from "util/ApiHelperDelete";
import getRequest from "util/APIHelperGet";
import postRequest from "util/APIHelperPost";

export const getEmployeeDocumentCount = async (employeeId) => {
  const { payload } = await getRequest(`${baseUrl}/employees/${employeeId}/document/count`);
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const getEmployeeDocumentList = async (employeeId, limit, page, orderBy, filter) => {
  const { payload } = await getRequest(
    `${baseUrl}/employees/${employeeId}/document?limit=${limit}&page=${page}&orderBy=${encodeURIComponent(JSON.stringify(orderBy))}&filter=${encodeURIComponent(JSON.stringify(filter))}`
  );
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const getUploadDocumentSignedUrl = async (fileName, fileType, fileSize, documentType = "Employee Uploads") => {
  const { payload } = await getRequest(
    `${baseUrl}/v2/employees/document/upload-url?fileName=${encodeURIComponent(fileName)}&fileType=${encodeURIComponent(fileType)}&fileSize=${fileSize}&documentType=${documentType}`
  );
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const getUploadDocumentSignedUrlForExistingEmployee = async (
  fileName,
  fileType,
  fileSize,
  documentType = "Employee Uploads",
  employeeId
) => {
  const { payload } = await getRequest(
    `${baseUrl}/v2/employees/${employeeId}/document/upload-url?fileName=${encodeURIComponent(fileName)}&fileType=${encodeURIComponent(fileType)}&fileSize=${fileSize}&documentType=${documentType}`
  );
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const saveEmployeeDocumentInfo = async (employeeId, payload) => {
  const { response } = await postRequest(`${baseUrl}/employees/${employeeId}/document/info`, JSON.stringify(payload));
  if (response?.error) {
    throw new Error(response?.error[0]?.description);
  }
  return response;
};

export const saveDocumentsOfEmployee = async (employeeId, payload) => {
  const { response } = await postRequest(
    `${baseUrl}/employees/${employeeId}/documents/metadata`,
    JSON.stringify(payload)
  );
  if (response?.error) {
    throw new Error(response?.error[0]?.description);
  }
  return response;
};

export const downloadEmployeeDocument = async (employeeId, documentId) => {
  const { payload } = await getRequest(`${baseUrl}/employees/${employeeId}/document/${documentId}/url`);
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const deleteEmployeeDocument = async (employeeId, documentId) => {
  const { payload } = await deleteRequest(`${baseUrl}/employees/${employeeId}/document/${documentId}`);
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return payload;
};

export const updateEmployeeDocumentInfo = async (employeeId, documentId, payload) => {
  const { response } = await postRequest(
    `${baseUrl}/employees/${employeeId}/document/${documentId}`,
    JSON.stringify(payload),
    undefined,
    "PATCH"
  );
  if (response?.error) {
    throw new Error(response?.error[0]?.description);
  }
  return response;
};
