import { Box, Typography } from "@mui/material";

import Button from "components/Button/Button";
import "components/Screens/Feedback/Feedback.css";

function CompanyFeedbackSuccessMessage({ responseText, onBack }) {
  return (
    <Box>
      <Typography variant="h3" className="successTitle">
        {responseText}
      </Typography>
      <Button type="button" variant="primary" size="md" className="success-back-button" onClick={onBack}>
        Go back
      </Button>
    </Box>
  );
}

export default CompanyFeedbackSuccessMessage;
