import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";

export default async (token) => {
  const response = await postRequest(`${baseUrl}/employee/auth0`, JSON.stringify({ token }), true, "POST");
  const { payload } = response;
  if (payload?.error) {
    throw new Error(payload?.error[0]?.description);
  }
  return response;
};
