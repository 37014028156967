import React from "react";

function PeruFlag({ width, height }) {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_239_6927)">
        <path
          d="M13.0012 26C20.1809 26 26.0012 20.1797 26.0012 13C26.0012 5.8203 20.1809 0 13.0012 0C5.82152 0 0.0012207 5.8203 0.0012207 13C0.0012207 20.1797 5.82152 26 13.0012 26Z"
          fill="#F0F0F0"
        />
        <path
          d="M26.001 13.0003C26.001 7.41083 22.4731 2.64572 17.5227 0.80896V25.1918C22.4731 23.3549 26.001 18.5899 26.001 13.0003Z"
          fill="#D80027"
        />
        <path
          d="M0.000610352 13.0003C0.000610352 18.5899 3.52849 23.3549 8.4789 25.1918V0.80896C3.52849 2.64572 0.000610352 7.41083 0.000610352 13.0003Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_239_6927">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PeruFlag;
