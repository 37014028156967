import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import classNames from "classnames";

import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid, IconButton, Avatar, List, ListItem, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { formatDateDDMMYYYY, getValue } from "components/Screens/CommonUtils";
import { baseUrl } from "util/APIBaseUrl";
import { usePermissions } from "components/Hook";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";

import HomeIcon from "assets/svgs/HomeIcon";
import PayIcon from "assets/svgs/PayIcon";
import PayrollInformationIcon from "assets/svgs/PayrollInformationIcon";
import PayrollHistoryIcon from "assets/svgs/PayrollHistoryIcon";
import WorkforceIcon from "assets/svgs/WorkforceIcon";
import TimeoffIcon from "assets/svgs/TimeoffIcon";
import HolidaysIcon from "assets/svgs/HolidaysIcon";
import DocumentIcon from "assets/svgs/DocumentIcon";
import PaymentMethodIcon from "assets/svgs/PaymentMethodIcon";
import AccountSettingsIcon from "assets/svgs/AccountSettingsIcon";
import BlogIcon from "assets/svgs/BlogIcon";
import ReferralsIcon from "assets/svgs/ReferralsIcon";
import CommunityIcon from "assets/svgs/CommunityIcon";
import EdgeLogoHalf from "assets/svgs/EdgeLogo";
import EdgeLogoV2 from "assets/images/edge-logo-v2.svg";
import EdgeLogoV3 from "assets/images/edge-logo-v3.svg";
import CreditCardIcon from "assets/svgs/CreditCardIcon";
import VivoIcon from "assets/svgs/VivoIcon";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import "./sidebar.component.scss";

import { REFERRAL_URLS } from "constants";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

const drawerWidth = 219;
const characterLimit = 20;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: "none",
  visibility: "visible !important",
  transform: "none !important",
  backgroundColor: "#3D43BB",
  color: "#FAFAFF",
  borderRadius: "0px !important",
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    position: "absolute",
  },
});

const openedMixinForPaper = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: "none",
  visibility: "visible !important",
  transform: "none !important",
  backgroundColor: "#3D43BB",
  color: "#FAFAFF",
  borderRadius: "0px !important",
  boxShadow: "none",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  border: "none",
  display: "none",
  zIndex: "111111111",
  backgroundColor: "#3D43BB",
  color: "#FAFAFF",
  borderRadius: "0px !important",
  boxShadow: "none",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    display: "inherit",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixinForPaper(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#292A3D",
    color: "#F5F5F5",
    borderRadius: "8px",
    fontSize: "12px",
  },
}));

const getLogoComponent = ({ loggedInUserType, platfromRebrandPhase1, userAdditionalInfo, companyProfileUrl }) => {
  if (loggedInUserType !== "edge") {
    return <Avatar alt={userAdditionalInfo?.companyName} src={companyProfileUrl} />;
  }
  if (platfromRebrandPhase1) {
    return <EdgeLogo icon size="lg" state="white" />;
  }
  return <EdgeLogoHalf />;
};

const getSmallSidebarLogo = ({ loggedInUserType, platfromRebrandPhase1, userAdditionalInfo, companyProfileUrl }) => {
  if (loggedInUserType !== "edge") {
    return <Avatar alt={userAdditionalInfo?.companyName} src={companyProfileUrl} />;
  }
  if (platfromRebrandPhase1) {
    return <EdgeLogo icon size="md" state="white" />;
  }
  return <EdgeLogoHalf />;
};

const openCommunity = () => window.open("https://community.onedge.co", "_blank");

export function SmallSideBar(props) {
  const {
    userAdditionalInfo,
    platformConfig,
    loggedInUserType,
    loggedInUser,
    companyProfileUrl,
    location,
    getReferralUrl,
  } = props;

  const { referralUrl } = userAdditionalInfo;

  const navigate = useNavigate();
  const classes = useStyles();
  const { capturingReasonsForInactiveEmployees, platfromRebrandPhase1 } = useFlags();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div>
          {getSmallSidebarLogo({
            loggedInUserType,
            platfromRebrandPhase1,
            userAdditionalInfo,
            companyProfileUrl,
          })}
        </div>
        <br />
        <Tooltip classes={{ tooltip: classes.customTooltip }} id="home" title="Home" placement="right">
          <div
            style={{ margin: "10px", cursor: "pointer" }}
            className="collapsed-sidebar-menuitem"
            onClick={() => navigate("/main/dashboard")}
          >
            <HomeIcon />
          </div>
        </Tooltip>

        {loggedInUserType === "customer" && (
          <>
            <div className="collapsed-sidebar-menus-bottom-border" />
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              id="payroll-information"
              title="Payroll Information"
              placement="right"
            >
              <div
                style={{ cursor: "pointer" }}
                id="payroll-information"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/payroll",
                })}
                onClick={() => navigate("payroll")}
              >
                <PayrollInformationIcon />
              </div>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              id="payroll-history"
              title="Payroll History"
              placement="right"
            >
              <div
                style={{ cursor: "pointer" }}
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/payroll?history=true",
                })}
                onClick={() => navigate("payroll?history=true")}
              >
                <PayrollHistoryIcon />
              </div>
            </Tooltip>
          </>
        )}
        {loggedInUserType === "employee" && (
          <>
            <div className="collapsed-sidebar-menus-bottom-border" />
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Paystubs" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="paystubs"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/paystubs",
                })}
                onClick={() => navigate("paystubs")}
              >
                <PayIcon />
              </div>
            </Tooltip>
          </>
        )}
        {loggedInUserType === "edge" && (
          <>
            <div className="collapsed-sidebar-menus-bottom-border" />
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Your Workforce" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="workforce"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/workforce",
                })}
                onClick={() => navigate("workforce")}
              >
                <PayIcon />
              </div>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Customers" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="customers"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/customers",
                })}
                onClick={() => navigate("customers")}
              >
                <PayIcon />
              </div>
            </Tooltip>
          </>
        )}

        {loggedInUserType === "employee" && (
          <>
            <div className="collapsed-sidebar-menus-bottom-border" />
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Upcoming Holidays" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="upcoming-holidays"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/holidays",
                })}
                onClick={() => navigate("holidays")}
              >
                <HolidaysIcon />
              </div>
            </Tooltip>
            {userAdditionalInfo?.customerId && (
              <Tooltip classes={{ tooltip: classes.customTooltip }} title="Time-off" placement="right">
                <div
                  style={{ cursor: "pointer" }}
                  id="time-off"
                  className={classNames({
                    "collapsed-sidebar-menuitem": true,
                    "selected-left-menu": location.pathname === "/main/timeoff",
                  })}
                  onClick={() => navigate("timeoff")}
                >
                  <TimeoffIcon />
                </div>
              </Tooltip>
            )}
          </>
        )}

        {loggedInUserType === "employee" && (
          <>
            <div className="collapsed-sidebar-menus-bottom-border" />
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Your Peers" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="peers"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/peers",
                })}
                onClick={() => navigate("peers")}
              >
                <PayIcon />
              </div>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Community" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="community"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/community",
                })}
                onClick={openCommunity}
              >
                <CommunityIcon />
              </div>
            </Tooltip>
          </>
        )}
        {loggedInUserType === "customer" && (
          <>
            <div className="collapsed-sidebar-menus-bottom-border" />
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Workforce" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="workforce"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/workforce",
                })}
                onClick={() => navigate("workforce")}
              >
                <WorkforceIcon />
              </div>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Holiday Calendar" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="workforce-holidays"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/holidays",
                })}
                onClick={() => navigate("holidays")}
              >
                <HolidaysIcon />
              </div>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Time-off" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="time-off"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/timeoff/leave",
                })}
                onClick={() => navigate("timeoff/leave#Pending")}
              >
                <TimeoffIcon />
              </div>
            </Tooltip>
          </>
        )}

        <div className="collapsed-sidebar-menus-bottom-border" />
        {loggedInUserType === "customer" && userAdditionalInfo?.status === "enabled" && (
          <Tooltip classes={{ tooltip: classes.customTooltip }} title="Payment Methods" placement="right">
            <div
              style={{ cursor: "pointer" }}
              id="payment-methods"
              className={classNames({
                "collapsed-sidebar-menuitem": true,
                "selected-left-menu": location.pathname === "/main/payment",
              })}
              onClick={() => navigate("payment")}
            >
              <PaymentMethodIcon />
            </div>
          </Tooltip>
        )}

        {((loggedInUserType == "customer" && loggedInUser?.permissions?.includes("CustomerAdmin")) ||
          (loggedInUserType == "edge" && loggedInUser?.permissions?.includes("EdgeAdmin"))) && (
          <Tooltip classes={{ tooltip: classes.customTooltip }} title="Account Setting" placement="right">
            <div
              style={{ cursor: "pointer" }}
              id="account-setting"
              className={classNames({
                "collapsed-sidebar-menuitem": true,
                "selected-left-menu": location.pathname === "/main/settings",
              })}
              onClick={() => navigate("settings")}
            >
              <AccountSettingsIcon />
            </div>
          </Tooltip>
        )}
        {loggedInUserType === "employee" && (
          <>
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Documents" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="documents"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/edocuments",
                })}
                onClick={() => navigate("edocuments")}
              >
                <DocumentIcon />
              </div>
            </Tooltip>
            {userAdditionalInfo?.allowedForPayoneer && (
              <Tooltip classes={{ tooltip: classes.customTooltip }} title="Edge Wallet" placement="right">
                <div
                  style={{ cursor: "pointer" }}
                  id="edge-wallet-mini"
                  className={classNames({
                    "collapsed-sidebar-menuitem": true,
                    "selected-left-menu": location.pathname === "/main/edge-wallet",
                  })}
                  onClick={() => navigate("edge-wallet")}
                >
                  <CreditCardIcon color="white" />
                </div>
              </Tooltip>
            )}
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Profile" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="profile"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/eprofile",
                })}
                onClick={() => navigate("eprofile")}
              >
                <WorkforceIcon />
              </div>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Company" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="company"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/company",
                })}
                onClick={() => navigate("company")}
              >
                <WorkforceIcon />
              </div>
            </Tooltip>
          </>
        )}
        {loggedInUserType === "edge" && (
          <>
            {loggedInUser?.permissions?.includes("EdgeAdmin") && (
              <Tooltip classes={{ tooltip: classes.customTooltip }} title="Finances" placement="right">
                <div
                  style={{ cursor: "pointer" }}
                  id="finances"
                  className={classNames({
                    "collapsed-sidebar-menuitem": true,
                    "selected-left-menu": location.pathname === "/main/workforcepayrolls",
                  })}
                  onClick={() => navigate("workforcepayrolls")}
                >
                  <PayIcon />
                </div>
              </Tooltip>
            )}
            {loggedInUser?.permissions?.includes("EdgeAdmin") && (
              <Tooltip title="Payroll Upload" placement="right">
                <div
                  style={{ cursor: "pointer" }}
                  id="payroll-upload"
                  className={classNames({
                    "collapsed-sidebar-menuitem": true,
                    "selected-left-menu": location.pathname === "/main/payrollsupload",
                  })}
                  onClick={() => navigate("payrollsupload")}
                >
                  <PayIcon />
                </div>
              </Tooltip>
            )}
            <Tooltip title="Time-off" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="time-off"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/timeoff/admin",
                })}
                onClick={() => navigate("timeoff/admin")}
              >
                <PayIcon />
              </div>
            </Tooltip>
          </>
        )}
        {loggedInUserType !== "edge" && (
          <>
            <div className="collapsed-sidebar-menus-bottom-border" />
            {loggedInUserType == "employee" && (
              <>
                <Tooltip classes={{ tooltip: classes.customTooltip }} title="Vivo" placement="right">
                  <div
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      "collapsed-sidebar-menuitem": true,
                      "selected-left-menu": location.pathname === "/main/vivo",
                    })}
                    onClick={() => navigate("vivo")}
                  >
                    <VivoIcon />
                  </div>
                </Tooltip>
                <Tooltip classes={{ tooltip: classes.customTooltip }} title="Referrals" placement="right">
                  <div
                    style={{ cursor: "pointer" }}
                    id="employee-referral"
                    className={classNames({
                      "collapsed-sidebar-menuitem": true,
                      "selected-left-menu": location.pathname === "/main/referrals",
                    })}
                    onClick={() => window.open(`${getReferralUrl()}`, "_blank")}
                  >
                    <ReferralsIcon />
                  </div>
                </Tooltip>
              </>
            )}
            {loggedInUserType == "customer" && (
              <Tooltip classes={{ tooltip: classes.customTooltip }} title="Blog" placement="right">
                <div
                  style={{ cursor: "pointer" }}
                  id="blog"
                  className={classNames({
                    "collapsed-sidebar-menuitem": true,
                    "selected-left-menu": location.pathname === "/main/blog",
                  })}
                  onClick={() => window.open(`${platformConfig?.payload?.BLOG_URL}`, "_blank")}
                >
                  <BlogIcon />
                </div>
              </Tooltip>
            )}

            {loggedInUserType === "employee" && (
              <div>
                <div className="collapsed-sidebar-menus-bottom-border" />
                <Tooltip classes={{ tooltip: classes.customTooltip }} title="Help & Support" placement="right">
                  <div
                    style={{ cursor: "pointer" }}
                    id="help"
                    className={classNames({
                      "collapsed-sidebar-menuitem": true,
                      "selected-left-menu": location.pathname === "/main/help",
                    })}
                    onClick={() => window.open("https://get.help.onedge.co/servicedesk/customer/portals", "_blank")}
                  >
                    <HelpOutlineOutlinedIcon sx={{ color: "white", width: 20, height: 21 }} />
                  </div>
                </Tooltip>
              </div>
            )}

            {loggedInUserType === "customer" && capturingReasonsForInactiveEmployees && referralUrl && (
              <div>
                <div className="collapsed-sidebar-menus-bottom-border" />
                <Tooltip classes={{ tooltip: classes.customTooltip }} title="Referrals" placement="right">
                  <div
                    id="referral-col"
                    className={classNames({
                      "collapsed-sidebar-menuitem": true,
                      "selected-left-menu": location.pathname === "/main/referrals",
                      "cursor-pointer": true,
                    })}
                    onClick={() => window.open(`${referralUrl}`, "_blank")}
                  >
                    <CampaignOutlinedIcon className="icon-style" />
                  </div>
                </Tooltip>
              </div>
            )}

            <Tooltip classes={{ tooltip: classes.customTooltip }} title="Feedback" placement="right">
              <div
                style={{ cursor: "pointer" }}
                id="feedback"
                className={classNames({
                  "collapsed-sidebar-menuitem": true,
                  "selected-left-menu": location.pathname === "/main/feedback",
                })}
                onClick={() => navigate("feedback")}
              >
                <BlogIcon />
              </div>
            </Tooltip>
          </>
        )}
      </div>
      <div
        style={{
          // width: "30%",
          paddingBottom: "68px",
        }}
      >
        {platfromRebrandPhase1 ? (
          <div className="edge-logo-style">
            <EdgeLogo icon size="sm" state="white" />
          </div>
        ) : (
          <img
            style={{
              marginBottom: "15px",
              padding: "0px 25px",
            }}
            alt="Edge Logo"
            src={EdgeLogoV3}
          />
        )}
        {loggedInUserType !== "edge" && (
          <>
            {/* <div className="collapsed-sidebar-menus-bottom-border" /> */}

            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title={`We love ${
                loggedInUserType === "customer" ? userAdditionalInfo?.companyName : loggedInUser?.name.split(" ")[0]
              }`}
              id="we heart"
              placement="right"
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular !important",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "20px",
                  color: "#FAFAFF",
                  display: "block",
                  textAlign: "center",
                }}
              >
                💛
              </Typography>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
}

function AppSideBar(props) {
  const { open } = props;
  const { capturingReasonsForInactiveEmployees, platfromRebrandPhase1 } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const employeeDetails = useSelector((state) => state?.GetEmployeeDetials?.employeeDetails);

  const { referralUrl } = userAdditionalInfo;

  const [companyProfileUrl, setCompanyProfileUrl] = useState(null);

  const { loggedInUser, loggedInUserType } = usePermissions();

  const getStartingDate = useCallback(() => {
    const date = {
      customer: formatDateDDMMYYYY(loggedInUser?.customerOnBoradingDate),
      edge: formatDateDDMMYYYY(userAdditionalInfo?.startDate),
      employee: formatDateDDMMYYYY(employeeDetails?.customerInfo?.joiningDate),
    };
    return date[loggedInUserType];
  }, [loggedInUserType, loggedInUser, userAdditionalInfo, employeeDetails]);

  useEffect(() => {
    const companyProfileLink = {
      customer: `${baseUrl}/customer/${userAdditionalInfo?.customerId}/logo?id=${userAdditionalInfo?.customerPictureVersion}`,
      edge: `${baseUrl}/edge/logo`,
      employee: `${baseUrl}/customer/${userAdditionalInfo?.customerId}/logo?id=${userAdditionalInfo?.customerPictureVersion}`,
    };
    setCompanyProfileUrl(companyProfileLink[loggedInUserType]);
  }, [userAdditionalInfo, loggedInUserType]);

  useEffect(() => {
    if (loggedInUser?.type == "employee") {
      dispatch(UserAdditionalInfo(`employee/${loggedInUser.userId}`));
    }
  }, []);

  const getDisplayStyle = () => {
    if (loggedInUserType === "customer") {
      return userAdditionalInfo?.companyName?.length > characterLimit ? "block" : "flex";
    }
    return loggedInUser?.name?.split(" ")[0].length > characterLimit ? "block" : "flex";
  };

  const getReferralUrl = () => {
    return REFERRAL_URLS[employeeDetails?.country];
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      sx={{ "& .MuiDrawer-paper": { justifyContent: "space-between" } }}
    >
      {!open && (
        <SmallSideBar
          userAdditionalInfo={userAdditionalInfo}
          platformConfig={platformConfig}
          companyProfileUrl={companyProfileUrl}
          loggedInUserType={loggedInUserType}
          loggedInUser={loggedInUser}
          getReferralUrl={getReferralUrl}
          location={location}
        />
      )}
      {open && (
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <Grid
              container
              item
              sx={{
                color: "#FAFAFF",
                width: "auto",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                ...(open && { flexDirection: "column" }),
              }}
            >
              <Grid
                item
                sx={{
                  padding: "0px",
                  width: "100%",
                }}
              >
                <br />
                <Tooltip title="Logo">
                  <IconButton sx={{ p: 0 }}>
                    {getLogoComponent({
                      loggedInUserType,
                      platfromRebrandPhase1,
                      userAdditionalInfo,
                      companyProfileUrl,
                    })}
                  </IconButton>
                </Tooltip>
                <Grid sx={{ padding: "0px 20px", textAlign: "center !important" }}>
                  {loggedInUserType !== "edge" ? (
                    <>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular !important",
                          fontWeight: "500",
                          fontSize: "12.775px",
                          lineHeight: "19px",
                          margin: "23px 0px 0px 0px",
                          textAlign: "start",
                          textWrap: "wrap ",
                          wordWrap: "break-word",
                        }}
                        id="company-name"
                      >
                        {getValue(userAdditionalInfo?.companyName)}
                      </Typography>

                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular !important",
                          fontWeight: "400",
                          fontSize: "10.95px",
                          lineHeight: "16px",
                          opacity: "0.4",
                          textAlign: "start",
                        }}
                        id="started-date"
                      >
                        {`Started on ${getStartingDate()}`}
                      </Typography>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular !important",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "20px",
                padding: "12px 12px",
              }}
            />
            <List sx={{ padding: "0px" }}>
              <ListItem
                id="home"
                disablePadding
                onClick={() => navigate("dashboard")}
                className={classNames({
                  "left-menu-list-item": true,
                  "selected-left-menu": location.pathname === "/main/dashboard}",
                })}
              >
                <ListItemButton sx={{ padding: "0px 23px" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div
                        style={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "18px",
                          padding: "4px 0px",
                        }}
                      >
                        Home
                      </div>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <div>
              <div className="sidebar-menu-category">Payroll</div>
              <div className="appsidebar-menus-bottom-border" />
              <List sx={{ padding: "0px", display: "flex", flexDirection: "column", gap: "3px" }}>
                {loggedInUserType === "customer" && (
                  <ListItem
                    id="payroll-information"
                    disablePadding
                    onClick={() => navigate("payroll")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/payroll",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayrollInformationIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Payroll Information</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "customer" && (
                  <ListItem
                    id="payroll-history"
                    disablePadding
                    onClick={() => navigate("payroll?history=true")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/payroll?history=true",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayrollHistoryIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Payroll History</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "employee" && (
                  <ListItem
                    id="paystubs"
                    disablePadding
                    onClick={() => navigate("paystubs")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/paystubs",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Pay Slips</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "edge" && (
                  <ListItem
                    id="workforce"
                    disablePadding
                    onClick={() => navigate("workforce")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/workforce",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Your Workforce</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "edge" && (
                  <ListItem
                    id="customers"
                    disablePadding
                    onClick={() => navigate("customers")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/customers",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Customers</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </div>
            {loggedInUserType === "employee" && (
              <div>
                <div className="sidebar-menu-category">
                  {userAdditionalInfo?.customerId && userAdditionalInfo?.timeoffFlag
                    ? "Time-off & Holidays"
                    : "Holidays"}
                </div>
                <div className="appsidebar-menus-bottom-border" />
                <List sx={{ padding: "0px", display: "flex", flexDirection: "column", gap: "3px" }}>
                  <ListItem
                    id="holidays"
                    disablePadding
                    onClick={() => navigate("holidays")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/holidays",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <HolidaysIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Holidays</div>} />
                    </ListItemButton>
                  </ListItem>

                  {userAdditionalInfo?.customerId && userAdditionalInfo?.timeoffFlag && (
                    <ListItem
                      id="time-off"
                      disablePadding
                      onClick={() => navigate("timeoff")}
                      className={classNames({
                        "left-menu-list-item": true,
                        "selected-left-menu":
                          location.pathname + location.search === "/main/timeoff" ||
                          location.pathname + location.search === "/main/timeoff/request",
                      })}
                    >
                      <ListItemButton sx={{ padding: "0px 23px" }}>
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          <TimeoffIcon />
                        </ListItemIcon>
                        <ListItemText primary={<div className="appsidebar-menu-title-text">Time-off</div>} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </div>
            )}
            {loggedInUserType === "employee" && (
              <div>
                <div className="sidebar-menu-category">Peers</div>
                <div className="appsidebar-menus-bottom-border" />
                <List sx={{ padding: "0px", display: "flex", flexDirection: "column", gap: "3px" }}>
                  <ListItem
                    id="peers"
                    disablePadding
                    onClick={() => navigate("peers")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/peers",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Your Peers</div>} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    id="community"
                    disablePadding
                    onClick={openCommunity}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/community",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <CommunityIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Community</div>} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </div>
            )}
            {loggedInUserType === "customer" && (
              <div>
                <div className="sidebar-menu-category">Workforce</div>
                <div className="appsidebar-menus-bottom-border" />
                <List sx={{ padding: "0px", display: "flex", flexDirection: "column", gap: "3px" }}>
                  <ListItem
                    id="workforce"
                    disablePadding
                    onClick={() => navigate("workforce")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/workforce",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <WorkforceIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Workforce</div>} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    id="holiday-calendar"
                    disablePadding
                    onClick={() => navigate("holidays")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/holidays",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <HolidaysIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Holiday Calendar</div>} />
                    </ListItemButton>
                  </ListItem>

                  {/* {userAdditionalInfo?.timeoffFlag && ( */}
                  <ListItem
                    id="time-off"
                    disablePadding
                    onClick={() => navigate("timeoff/leave#Pending")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/timeoff/leave",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <TimeoffIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Time-off</div>} />
                    </ListItemButton>
                  </ListItem>
                  {/* )} */}
                </List>
              </div>
            )}
            <div>
              <div className="sidebar-menu-category">Account</div>
              <div className="appsidebar-menus-bottom-border" />
              <List sx={{ padding: "0px", display: "flex", flexDirection: "column", gap: "3px" }}>
                {loggedInUserType === "customer" && userAdditionalInfo?.status === "enabled" && (
                  <ListItem
                    id="payment-methods"
                    disablePadding
                    onClick={() => navigate("payment")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/payment",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PaymentMethodIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Payment Methods</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {((loggedInUserType == "customer" && loggedInUser?.permissions?.includes("CustomerAdmin")) ||
                  (loggedInUserType == "edge" && loggedInUser?.permissions?.includes("EdgeAdmin"))) && (
                  <ListItem
                    id="account-settings"
                    disablePadding
                    onClick={() => navigate("settings")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/settings",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <AccountSettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Account Setting</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "employee" && (
                  <ListItem
                    id="documents"
                    disablePadding
                    onClick={() => navigate("edocuments")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/edocuments",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <DocumentIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Documents</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "employee" && userAdditionalInfo?.allowedForPayoneer && (
                  <ListItem
                    id="edge-wallet"
                    disablePadding
                    onClick={() => navigate("edge-wallet")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/edge-wallet",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <CreditCardIcon color="white" />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Edge Wallet</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "employee" && (
                  <ListItem
                    id="profile"
                    disablePadding
                    onClick={() => navigate("eprofile")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/eprofile",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <WorkforceIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Profile</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "employee" && (
                  <ListItem
                    id="company"
                    disablePadding
                    onClick={() => navigate("company")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/company",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <WorkforceIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Company</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "edge" && loggedInUser?.permissions?.includes("EdgeAdmin") && (
                  <ListItem
                    id="finances"
                    disablePadding
                    onClick={() => navigate("workforcepayrolls")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/workforcepayrolls",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Finances</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
                {loggedInUserType === "edge" && loggedInUser?.permissions?.includes("EdgeAdmin") && (
                  <ListItem
                    id="payrolls-upload"
                    disablePadding
                    onClick={() => navigate("payrollsupload")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/payrollsupload",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Payrolls Upload</div>} />
                    </ListItemButton>
                  </ListItem>
                )}

                {loggedInUserType === "edge" && (
                  <ListItem
                    id="timeoff"
                    disablePadding
                    onClick={() => navigate("timeoff/admin")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/timeoff/admin",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <PayIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">TimeOff</div>} />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </div>
            {loggedInUserType !== "edge" && (
              <div>
                <div className="sidebar-menu-category">Edge Resources</div>
                <div className="appsidebar-menus-bottom-border" />
                <List sx={{ padding: "0px", display: "flex", flexDirection: "column", gap: "3px" }}>
                  {loggedInUserType === "employee" && (
                    <>
                      <ListItem
                        disablePadding
                        onClick={() => navigate("vivo")}
                        className={classNames({
                          "left-menu-list-item": true,
                          "selected-left-menu": location.pathname + location.search === "/main/vivo",
                        })}
                      >
                        <ListItemButton sx={{ padding: "0px 23px" }}>
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            <VivoIcon />
                          </ListItemIcon>
                          <ListItemText primary={<div className="appsidebar-menu-title-text">Vivo</div>} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem
                        id="employee-referral"
                        disablePadding
                        onClick={() => window.open(`${getReferralUrl()}`, "_blank")}
                        className={classNames({
                          "left-menu-list-item": true,
                          "selected-left-menu": location.pathname + location.search === "/main/referrals",
                        })}
                      >
                        <ListItemButton sx={{ padding: "0px 23px" }}>
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            <ReferralsIcon />
                          </ListItemIcon>
                          <ListItemText primary={<div className="appsidebar-menu-title-text">Referrals</div>} />
                        </ListItemButton>
                      </ListItem>
                    </>
                  )}

                  {loggedInUserType === "customer" && (
                    <ListItem
                      id="blog"
                      disablePadding
                      onClick={() => window.open(`${platformConfig?.payload?.BLOG_URL}`, "_blank")}
                      className={classNames({
                        "left-menu-list-item": true,
                        "selected-left-menu": location.pathname + location.search === "/main/blog",
                      })}
                    >
                      <ListItemButton sx={{ padding: "0px 23px" }}>
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          <BlogIcon />
                        </ListItemIcon>
                        <ListItemText primary={<div className="appsidebar-menu-title-text">Blog</div>} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>

                <div>
                  {loggedInUserType === "employee" && (
                    <>
                      <div className="sidebar-menu-category">Contact Us</div>
                      <div className="appsidebar-menus-bottom-border" />
                      <ListItem
                        disablePadding
                        id="help"
                        onClick={() => window.open("https://get.help.onedge.co/servicedesk/customer/portals", "_blank")}
                        className={classNames({
                          "left-menu-list-item": true,
                          "selected-left-menu": location.pathname + location.search === "/main/help",
                        })}
                      >
                        <ListItemButton sx={{ padding: "0px 23px" }}>
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            <HelpOutlineOutlinedIcon sx={{ color: "white", width: 20, height: 21 }} />
                          </ListItemIcon>
                          <ListItemText primary={<div className="appsidebar-menu-title-text">Help & Support</div>} />
                        </ListItemButton>
                      </ListItem>
                    </>
                  )}

                  {loggedInUserType === "customer" && capturingReasonsForInactiveEmployees && referralUrl && (
                    <ListItem
                      id="referral"
                      disablePadding
                      onClick={() => window.open(`${referralUrl}`, "_blank")}
                      className={classNames({
                        "left-menu-list-item": true,
                        "selected-left-menu": location.pathname + location.search === "/main/referrals",
                      })}
                    >
                      <ListItemButton className="list-item-button">
                        <ListItemIcon className="list-item-icon">
                          <CampaignOutlinedIcon className="icon-style" />
                        </ListItemIcon>
                        <ListItemText primary={<div className="appsidebar-menu-title-text">Referrals</div>} />
                      </ListItemButton>
                    </ListItem>
                  )}

                  <ListItem
                    disablePadding
                    id="feedback"
                    onClick={() => navigate("feedback")}
                    className={classNames({
                      "left-menu-list-item": true,
                      "selected-left-menu": location.pathname + location.search === "/main/feedback",
                    })}
                  >
                    <ListItemButton sx={{ padding: "0px 23px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <BlogIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div className="appsidebar-menu-title-text">Feedback</div>} />
                    </ListItemButton>
                  </ListItem>
                </div>
              </div>
            )}
          </div>
          <Grid container className="edge-sidebar-logo" justifyContent="center" style={{ padding: "0 20px" }}>
            {" "}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {platfromRebrandPhase1 ? (
                <EdgeLogo size="sm" state="white" />
              ) : (
                <img
                  style={{
                    height: "47px",
                    width: "91px",
                    objectFit: "cover",
                  }}
                  alt="Edge Logo"
                  src={EdgeLogoV2}
                />
              )}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {loggedInUserType !== "edge" && (
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    fontWeight: "400",
                    fontSize: "13px",
                    lineHeight: "20px",
                    color: "#FAFAFF",
                    display: "block",
                  }}
                >
                  <div
                    id="we heart"
                    style={{
                      display: getDisplayStyle(),
                      justifyContent: "center",
                      textWrap: "wrap",
                      padding: "0px 20px",
                    }}
                  >
                    <div>We 💛 &nbsp;</div>
                    <div>
                      {` ${
                        loggedInUserType === "customer"
                          ? userAdditionalInfo?.companyName
                          : loggedInUser?.name.split(" ")[0]
                      }`}
                    </div>
                  </div>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
}

export default AppSideBar;
