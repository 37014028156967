import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "components/common/Modal";
import Text from "components/common/Text";
import Button from "components/common/Button";
import { AlertType } from "components/common/Snackbar";

import { updateEmployeeDocumentInfo } from "services/BambooHrTabs/documents";
import { showSnackbar } from "store/actions/Utility";

import "../Document.scss";

function DocumentRenameModal({
  open = false,
  onClose = () => {},
  document = {},
  employeeId = null,
  onDocumentRename = () => {},
}) {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileExtension, setFileExtension] = useState("");

  const { documentId, documentName } = document || {};

  const handleInputField = (e) => {
    setFileName(e.target.value);
  };

  const onRename = async () => {
    try {
      setIsLoading(true);
      const fileNameWithExtension = `${fileName}${fileExtension}`;
      await updateEmployeeDocumentInfo(employeeId, documentId, { documentName: fileNameWithExtension });
      dispatch(showSnackbar({ type: AlertType.success, message: "Document renamed successfully", dispatch }));

      onDocumentRename();
    } catch (error) {
      dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFileName(documentName);
  }, [documentName]);

  useEffect(() => {
    if (documentName) {
      const lastDotIndex = documentName.lastIndexOf(".");
      if (lastDotIndex !== -1) {
        setFileName(documentName.substring(0, lastDotIndex));
        setFileExtension(documentName.substring(lastDotIndex));
      } else {
        setFileName(documentName);
        setFileExtension("");
      }
    }
  }, [documentName]);

  return (
    <Modal open={open} onClose={onClose} title="Rename Document" width="552px">
      <div className="py-1">
        <Text
          name="documentName"
          label="Name"
          size="medium"
          placeholder="e.g. Signed Document"
          fontSize="16px"
          fontWeight="400"
          onChange={handleInputField}
          value={fileName}
        />
      </div>
      <div className="flex justify-end mb-05">
        <Button label="Cancel" variant="outlined" size="large" color="primary" onClick={onClose} />
        <div className="ml-05">
          <Button isLoading={isLoading} label="Save" size="large" color="primary" onClick={onRename} />
        </div>
      </div>
    </Modal>
  );
}

export default DocumentRenameModal;
