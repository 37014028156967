import { BG_COLORS } from "constants";

export function convertTimezonesToDropdownOptions(value) {
  return value?.map((key) => ({
    label: key.timezone || key.countryCode,
    value: key.timezone || key.countryCode,
  }));
}

export function getOrdinalSuffix(date) {
  if (date >= 11 && date <= 13) {
    return "th";
  }
  const lastDigit = date % 10;
  if (lastDigit === 1) {
    return "st";
  }
  if (lastDigit === 2) {
    return "nd";
  }
  if (lastDigit === 3) {
    return "rd";
  }
  return "th";
}

export function numberToCurrencyFormat(number) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getBackgroundColor() {
  return BG_COLORS.find((color) => color.path === window.location.pathname)?.color || "#F5F5FF";
}

export function checkIfPadding24Needed() {
  if (window.location.pathname === "/main/addEmployee") return false;
  return true;
}

export const shouldAddCustomHeightOnCurrentPage = () => {
  if (window.location.pathname === "/main/addEmployee") return false;
  return true;
};

export function getFileSizeToDisplay(fileSizeInBytes) {
  if (fileSizeInBytes > 1024 * 1024) {
    return `${(fileSizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  }
  return `${(fileSizeInBytes / 1024).toFixed(2)} KB`;
}
export const formatDate = (date) => {
  if (!date) return null;
  const d = new Date(date);
  return d.toISOString().split("T")[0];
};

export const formatPaymentDateMonth = (dateString, includeDay = false) => {
  const [year, month, day] = dateString.split("-");
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const formattedMonth = monthNames[parseInt(month, 10) - 1];

  return includeDay ? `${formattedMonth} ${parseInt(day, 10)}, ${year}` : `${formattedMonth}, ${year}`;
};

export const getNationalIDHelperText = (country) => {
  if (country === "Pakistan") return "Enter 13-character CNIC number";
  if (country === "Peru") return "Enter 9-character DNI number";
  if (country === "United States") return "Enter 9-character SSN number";

  return "";
};

export const getUserRole = (userItem) => {
  const permission = userItem?.permissions?.[0];

  if (permission === "EdgeAdmin") {
    return "Admin";
  }

  if (permission === "CustomerAdmin" && userItem.mainUser === true) {
    return "Account Owner";
  }

  if (permission === "CustomerAdmin") {
    return "Customer Admin";
  }

  if (permission === "EdgeEmployee") {
    return "Employee";
  }

  if (permission === "CustomerEmployee") {
    return "Customer User";
  }

  return permission;
};

export const getDocumentPermissions = (permissions) => {
  if (!permissions || !permissions.enabled) return false;

  const allowedRoles = permissions.allowedRoles || [];
  const userPermissions = JSON.parse(localStorage.getItem("loggedInUser"))?.permissions || [];
  return userPermissions.some((permission) => allowedRoles.includes(permission));
};

export function pluralize(word, count, pluralForm) {
  const plural = pluralForm || `${word}s`;
  return count === 1 ? word : plural;
}
