// employee constants
import { RESET_SHOW_SNACKBAR, SHOW_SNACKBAR } from "../../constants/Snackbar";

const INIT_STATE = {
  snackbar: {
    open: false,
    type: "",
    message: "",
    snackbarMessageContent: null,
    prefixIcon: null,
    callToAction: {},
  },
};

export default (payload = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      const {
        open = true,
        type = "",
        message = "",
        snackbarMessageContent = null,
        prefixIcon = null,
        callToAction = {},
      } = action?.payload || {};

      return {
        snackbar: {
          open,
          type,
          message,
          snackbarMessageContent,
          prefixIcon,
          callToAction,
        },
      };
    }
    case RESET_SHOW_SNACKBAR:
      return INIT_STATE;

    default:
      return payload;
  }
};
