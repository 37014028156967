import React from "react";

function CostaRicaFlag({ width, height }) {
  return (
    <svg
      width={width || "29"}
      height={height || "29"}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_293_4937)">
        <path
          d="M12.9996 26C20.1793 26 25.9996 20.1797 25.9996 13C25.9996 5.8203 20.1793 0 12.9996 0C5.81993 0 -0.000366211 5.8203 -0.000366211 13C-0.000366211 20.1797 5.81993 26 12.9996 26Z"
          fill="#F0F0F0"
        />
        <path
          d="M25.191 8.47791H0.808224C0.285685 9.88622 -0.000366211 11.4095 -0.000366211 12.9996C-0.000366211 14.5898 0.285685 16.113 0.808224 17.5213H25.1911C25.7136 16.113 25.9996 14.5898 25.9996 12.9996C25.9996 11.4095 25.7136 9.88622 25.191 8.47791Z"
          fill="#D80027"
        />
        <path
          d="M12.9983 -0.00170898C9.05817 -0.00170898 5.5277 1.75146 3.14368 4.52001H22.8529C20.469 1.75146 16.9385 -0.00170898 12.9983 -0.00170898Z"
          fill="#0052B4"
        />
        <path
          d="M22.853 21.4771H3.14368C5.5277 24.2456 9.05817 25.9988 12.9983 25.9988C16.9385 25.9988 20.469 24.2456 22.853 21.4771Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_293_4937">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CostaRicaFlag;
