import { useState } from "react";
import { Modal, Box, Grid, Button, Typography } from "@mui/material";

import ProductFeedbackForm from "./ProductFeedbackForm";
import CompanyFeedbackForm from "./CompanyFeedbackForm";

import "./Feedback.css";

function FeedbackModal() {
  const [activeForm, setActiveForm] = useState(null);

  const handleCompanyFeedback = () => {
    setActiveForm("company");
  };

  const handleProductFeedback = () => {
    setActiveForm("product");
  };

  const handleCancel = () => {
    setActiveForm(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setActiveForm(null);
  };

  const renderForm = () => {
    switch (activeForm) {
      case "company":
        return <CompanyFeedbackForm onSubmit={handleSubmit} onCancel={handleCancel} />;
      case "product":
        return <ProductFeedbackForm onSubmit={handleSubmit} onCancel={handleCancel} />;
      default:
        return null;
    }
  };

  return (
    <Modal open className="modalContainer">
      <Box className="modalContent">
        {activeForm ? (
          renderForm()
        ) : (
          <Grid container spacing={2} direction="column">
            <Grid className="gridItem">
              <Button fullWidth variant="outlined" onClick={handleCompanyFeedback} className="Modalbutton">
                <Box className="Modalbox">
                  <Typography variant="h1" className="ModalbuttonText">
                    Company Feedback
                  </Typography>
                  <Typography variant="paragraph" className="ModalbuttonSubtext">
                    Share general feedback to help us improve your experience through Edge.
                  </Typography>
                </Box>
              </Button>
            </Grid>
            <Grid className="gridItem">
              <Button fullWidth variant="outlined" onClick={handleProductFeedback} className="Modalbutton">
                <Box className="Modalbox">
                  <Typography variant="h1" className="ModalbuttonText">
                    Product Feedback
                  </Typography>
                  <Typography variant="paragraph" className="ModalbuttonSubtext">
                    Submit feature requests, bug reports and general feedback related to the product, so we can deliver
                    a great experience.
                  </Typography>
                </Box>
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
}

export default FeedbackModal;
